import React from 'react';

const QR = () => {
	return (
		<div>
			<div style={{"margin-bottom":"10px"}}/>
			<img src="images/header/website-qr.png" alt="andrii-korzh qr-code"/>
		</div>
	);
}

export default QR;